import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { createGlobalStyle} from 'styled-components'
import BlogLayout from '../components/BlogLayout'
const GlobalStyle = createGlobalStyle`
    p{
        font-size: 1.4rem;
        font-family: 'Raleway';
        line-height: 160%;
    }
    img{
        max-width: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        margin: 5% auto;
        display: block;
    }
    h2{
        font-size: 2rem;
        font-family: 'Raleway';
    }
    code{
        background: lightgray;
    }
`
const Banner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top:1vh;
    background-color: transparent;
`
const Thumbnail = styled.img`
    height: 45vh;
    border-left-style: none;
    border-right-style: none;
    object-fit: cover;
    margin: 0;
    padding: none;
`
const Title= styled.h1`
    width: 100%;
    font-family: 'Raleway';
    margin-bottom: 0;
    font-size: 2.2vw;
    margin-left: 10%;
`
const Date= styled.h3`
    width: 90%;
    font-family: 'Raleway';
    margin-top: .5vw;
    font-size: 1.15vw; 
    margin-left: 10%;
    margin-bottom: 5%;
`
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width:100%;
    background: white;
    border-width: 1px;
    border-top-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-color: lightgray;
`
const Content = styled.div`
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    font-family: 'Raleway';
    background-color: white;
    padding-bottom: 5%;
`

class Template extends Component{
   state={

   }
    render(){
    const {markdownRemark} = this.props.data;
    const {frontmatter, html} = markdownRemark;
    return(
        <BlogLayout>
            <GlobalStyle />
            <style>@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');</style>
            <Banner>
                <Thumbnail src={"/"+frontmatter.thumbnail}></Thumbnail>
            </Banner>
            <Container>
                    <Title>{frontmatter.title}</Title>
                    <Date>{frontmatter.date}</Date>
                <Content>
                    <div dangerouslySetInnerHTML={{__html: html}}></div>
                </Content>
            </Container>
        </BlogLayout>
    )
    }
}
export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: {path: { eq: $path} }) {
            html
            frontmatter{
                date(formatString: "MMMM DD, YYYY")
                path
                title
                thumbnail
            }
        }
    }
`
export default Template
